import React, { useEffect, useRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Button, ClickAwayListener, Grow, Paper, Popper, MenuItem, MenuList, Stack } from '@mui/material'

export default function FilterMenu(props) {

  const { fetchFilter, filter } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const fetchShopify = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    fetchFilter('SHOPIFY')
    setOpen(false);
  };

  const fetchSample = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    fetchFilter('SAMPLE')
    setOpen(false);
  };

  const fetchInventory = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    fetchFilter('INVENTORY')
    setOpen(false);
  };

  const fetchAll = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    fetchFilter('ALL')
    setOpen(false);
  };

  
  return (
    <Stack direction="row" spacing={2}>
      <div className="filter-menu-template">
        <Button
          ref={anchorRef}
          color="primary"
          className="button__filter text--nunito"
          id="composition-button"
          aria-controls={open ? 'composition-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Filter By: {filter}
          <ArrowDropDownIcon />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem onClick={fetchShopify}>CUSTOMER ORDERS</MenuItem>
                    <MenuItem onClick={fetchInventory}>INVENTORY ORDERS</MenuItem>
                    <MenuItem onClick={fetchSample}>SAMPLE ORDERS</MenuItem>
                    <MenuItem onClick={fetchAll}>ALL ORDERS</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}