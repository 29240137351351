

export const TOKEN = process.env.REACT_APP_API_TOKEN ||  'db335b7f652ebca60f3832d1fce679f44ccd243e';
export const API_URL = process.env.REACT_APP_API_URL || 'https://blanka-dev.ngrok.io';
export const PAGE_SIZE = 50;

export const LBS_CONVERSION = 0.0022046226

export const UNFULFILLED = 'UNFULFILLED';
export const SHIPPED = 'SHIPPED';
export const RECEVIED = 'RECEIVED';
export const CANCELLED = 'CANCELLED';
export const FULLFILLED_BY_MERCHANT = 'FULLFILLED_BY_MERCHANT';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const PAYMENT_REQUIRED = 'PAYMENT_REQUIRED';

export const DHL = 'DHL';
export const UPS = 'UPS';
export const CANADA_POST = 'CANADA_POST';
export const FEDEX = 'FEDEX';
export const CHIT_CHAT = 'CHIT_CHAT';

export const CARRIER_OPTIONS = [
  { value: 'DHL', label: 'DHL' },
  { value: 'UPS', label: 'UPS' },
  { value: 'CANADA_POST', label: 'CANDA POST' },
  { value: 'FEDEX', label: 'FEDEX' },
  { value: 'CHIT_CHAT', label: 'USPS' }
];

export const ORDER_STATUS_OPTIONS = [
  { value: UNFULFILLED, label: UNFULFILLED },
  { value: SHIPPED, label: SHIPPED },
  { value: RECEVIED, label: RECEVIED },
  { value: FULLFILLED_BY_MERCHANT, label: 'FULLFILLED BY MERCHANT' },
  { value: CANCELLED, label: CANCELLED },
  { value: PAYMENT_ERROR, label: 'PAYMENT ERROR' },
  { value: PAYMENT_REQUIRED, label: 'PAYMENT REQUIRED' }
]

export const headers = [
  { label: 'SKU', key: 'sku' },
  { label: 'Product Name', key: 'title' },
  { label: 'Color Code', key: 'color_code' },
  { label: 'Color Name', key: 'color_name' },
  { label: 'Quantity', key: 'quantity' },
  { label: 'Cost', key: 'cost' },
];