import React from 'react';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';

export default function OrderDataContainer(props) {
  const { title, orderDetail, totalClass, isLink, linkUrl } = props;

  return (
    <div className={`order-data-container ${totalClass ? 'pt-10' : ''}`}>
      <Typography
        className={`text__information ${
          totalClass ? 'text__total' : ''
        } mw-300`}>
        {' '}
        {title}{' '}
      </Typography>
      {isLink ? 
      (<Link target='_blank' href={linkUrl} className={`text__information text--bold ${totalClass ? 'text__total' : ''}`}>
        {' '}
        {linkUrl?orderDetail:''}</Link> 
      ):(
        <Typography
        className={`text__information text--bold ${
          totalClass ? 'text__total' : ''
        }`}>
        {' '}
        {orderDetail}
        </Typography>
      )}
      

      
    </div>
  );
}
