import axios from 'axios';
import {TOKEN,API_URL, PAGE_SIZE} from './constants';

export const fetchBrandPlateOrders = async (page = 0) => {
  const { data } = await axios.get(`${API_URL}/api/admin/brandplate-orders/?page=${page}&page_size=${PAGE_SIZE}`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  })
  // console.log(data);
  return data;
}

export const fetchBrandPlateDetails = async (id) => {
  const { data } = await axios.get(`${API_URL}/api/admin/brandplate-orders/${id}`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  })
  // console.log(data);
  return data;
}

export const changeBrandPlateOrderStatus = async (status, id) => {
  await axios.patch(`${API_URL}/api/admin/brandplate-orders/${id}/`, 
    {
      status: status
    },
    {
      headers: {
        'Authorization': `token ${TOKEN}`
      }
    }
  )
}