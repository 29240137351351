import axios from 'axios';
import {TOKEN,API_URL} from './constants';


export const fetchPlanCounts = async () => {
  const { data } = await axios.get(`${API_URL}/api/analytics/`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  })
  return data;
}


export const fetchCustomerOrdersTimeseries = async () => {
  const { data } = await axios.get(`${API_URL}/api/analytics/customer-orders/`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  })
  return data;
}

