import BrandPlateOrders from '../views/BrandPlateOrders.jsx';
import Reports from '../views/Reports';
import Orders from '../views/Orders';


const routes = [
  {
    name: 'Orders',
    path: '/',
    exact: true,
    component: Orders,
    menu: true,
    icon: 'adjust',
  },
  {
    name: 'Brand Plate Orders',
    path: '/brand-plate-orders',
    exact: true,
    component: BrandPlateOrders,
    menu: true,
    icon: 'aspect_ratio',
  },
  {
    name: 'Reports',
    path: '/reports',
    exact: true,
    component: Reports,
    menu: true,
    icon: 'assessment',
  },
]

export default routes;