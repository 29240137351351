/**
 * Sorts by last digits of a sku
 * @param {string} skuA first sku
 * @param {string} skuB sku to compare to
 * @return {boolean} true if skuA bigger than skuB
 */
export function useSort() {

    return (skuA,skuB) => { 
        let skuALastFour = /[^-]*$/.exec(skuA)[0];
        let skuBLastFour = /[^-]*$/.exec(skuB)[0];
        return skuALastFour.localeCompare(skuBLastFour)
    }
}
