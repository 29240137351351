import React, { useState } from "react";
import { useQuery } from 'react-query';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";

import FilterMenu from '../components/FilterMenu';
import TablePaginationBrandPlate from '../components/TablePaginationBrandPlate';

import { fetchBrandPlateOrders } from '../api/brandPlateOrdersApi';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableFooter,
} from '@mui/material';

function Orders() {
  let history = useHistory();

  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  const { status, data, error } = useQuery(
    ['brandplate-orders', page],
    () => fetchBrandPlateOrders(page),
    { keepPreviousData: true, staleTime: 5000 }
  );

  const handleOrderClick = (order) => {
    history.push(`/brand-plate-orders/${order.id}/`);
  };

  const headers = [
    { label: 'Created At', key: 'created_at' },
    { label: 'Merchant Name', key: 'merchant_name' },
    { label: 'Shop', key: 'shop.url' },
    { label: 'Shop Email', key: 'shop_email' },
    { label: 'Brand Plate ID', key: 'id' },
    { label: 'Status', key: 'status' },
    { label: 'Payment ID', key: 'charge_id' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className='orders-template'>
      <div className='headers-container'>
        <Typography
          variant='h4'
          className='text__header text--nunito text--upper'>
          All Brand Plate Orders
        </Typography>

        {data?.results && (
          <div>
            <CSVLink
              onClick={() => toast.success('Exported!')}
              className="button__export MuiButtonBase-root MuiButton-root text--nunito text--upper"
              data={data.results}
              headers={headers}>
              Export
            </CSVLink>
          </div>
        )}
      </div>

      {status === 'loading' ? (
        <Typography className="text--nunito text--bold text--upper pt-50 text--fs-20">Loading, Please Wait</Typography>
      ) : status === 'error' ? (
        <Typography className="text--nunito text--bold text--upper pt-50 text--fs-20">{ error }</Typography>
      ) : (
        <div>
          <TableContainer className='table-container' component={Paper}>
            <FilterMenu />
            <Table padding='none' size='small' aria-label='table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text--upper text--nunito'
                    align='center'>
                    Created At
                  </TableCell>

                  <TableCell
                    className='text--upper text--nunito'
                    align='center'>
                    Shop
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='center'>
                    Email
                  </TableCell>

                  <TableCell
                    className='text--upper text--nunito'
                    align='center'>
                    Status
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='center'>
                    Payment ID
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.results.map((result) => (
                  <TableRow
                    hover
                    onClick={(e) => handleOrderClick(result)}
                    key={result.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 1 } }}>
                    <TableCell padding='none' align='center'>
                      {moment(result.created_at).format('L h:mm:ss a')}
                    </TableCell>

                    <TableCell padding='none' align='center'>
                      {result.shop.url}
                    </TableCell>
                    <TableCell padding='none' align='center'>
                      {result.shop_email}
                    </TableCell>

                    <TableCell padding='none' align='center'>
                      {result.status}
                    </TableCell>
                    <TableCell padding='none' align='center'>
                      {result.charge_id}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    rowsPerPage={rowsPerPage}
                    colSpan={3}
                    page={page}
                    count={data.count}
                    onChangePage={handleChangePage}
                    ActionsComponent={TablePaginationBrandPlate}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export default Orders;