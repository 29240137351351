import axios from 'axios';

import {TOKEN,API_URL} from './constants';

export const fetchShopifyDetails = async (id) => {
  const { data } = await axios.get(`${API_URL}/api/admin/customer-orders/${id}`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  })
  return data;
}

export const addInputDetailsShopify = async (data) => {
  return await axios.patch(`${API_URL}/api/admin/customer-orders/${data.id}/`, data,
  {
    headers: {
      'Authorization': `token ${TOKEN}`
      }
    }
  )
}