import React, { useState, useEffect } from "react";

import { MenuItem, FormControl, Select } from '@mui/material'

export default function OrderStatusSelect(props) {
  const { orderStatus, onChangeOrderStatus } = props;
  const [status, setStatus] = useState('');

  const handleChange = (event) => {
    setStatus(event.target.value);
    onChangeOrderStatus(event.target.value);
  };

  useEffect(() => {
    setStatus(orderStatus)
  }, [orderStatus]);

  return (
    <div className="order-status-select-container">
      <FormControl sx={{ width: 175 }} variant="outlined">
      <Select
        labelId="order-status-select-label"
        id="order-status-select"
       
        value={!status ? '' : status}
        autoWidth
        onChange={handleChange}
      >
        <MenuItem value='UNFULFILLED'>UNFULFILLED</MenuItem>
        <MenuItem value='SHIPPED'>SHIPPED</MenuItem>
        <MenuItem value='ORDERED'>ORDERED</MenuItem>
        <MenuItem value='RECEIVED'>RECEIVED</MenuItem>
        <MenuItem value='CANCELLED'>CANCELLED</MenuItem>
        <MenuItem value='PAYMENT_ERROR'>PAYMENT ERROR</MenuItem>
        <MenuItem value='PAYMENT_REQUIRED'>PAYMENT REQUIRED</MenuItem>
      </Select>
      </FormControl>
    </div>
  );
}