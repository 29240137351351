import axios from 'axios';
import {TOKEN, API_URL} from './constants';

export const fetchInventoryDetails = async (id) => {
  const { data } = await axios.get(`${API_URL}/api/admin/inventory-orders/${id}`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  })
  // console.log(data);
  return data;
}

export const addInputDetailsInventory = (data) => {
  return axios.patch(`${API_URL}/api/admin/inventory-orders/${data.id}/`, data,
  {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  }
  )
}