import React, { useState } from "react";
import { useQuery } from 'react-query';
import moment from "moment";
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip,Label,BarChart, Bar,  XAxis, YAxis, CartesianGrid, Legend  } from 'recharts';
import Grid from '@mui/material/Grid';

import { Paper, Typography } from '@mui/material';

import { fetchPlanCounts,fetchCustomerOrdersTimeseries } from '../../api/reportsApi';
import {API_GET_CUSTOMER_ORDERS_TIMESERIES,API_GET_PLAN_COUNTS } from '../../api/api';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#e74c3c'];

// const RADIAN = Math.PI / 180;

/*
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};
*/
const headers = [
    { label: 'Date', key: 'date' },
    { label: 'Orders', key: 'number_of_orders' },
    { label: 'Shipping Cost', key: 'total_shipping_cost' },
    { label: 'Blanka Shipping Cost', key: 'total_blanka_shipping_cost' },
]

function Reports() {
  const [planData, setPlanData] = useState([]);
  const [customerOrderData, setCustomerOrderData] = useState([]);
  const [scalePlanData, setScalePlanData] = useState([]);
  const [growthPlanData, setGrowthPlanData] = useState([]);
  const [freePlanData, setFreePlanData] = useState([]);
  const [vipPlanData, setVipPlanData] = useState([]);

  
  const { } = useQuery(
    [API_GET_PLAN_COUNTS , planData],
    () => fetchPlanCounts(),
    {
        onSuccess: (data) => {
            
            setScalePlanData([
                {name: 'active', value:data[0].active },
                {name: 'trialing', value:data[0].trialing },
                {name: 'pending', value:data[0].pending },
                {name: 'past due', value:data[0].past_due },
                {name: 'unpaid', value:data[0].past_due }
            ]);
            
            setGrowthPlanData([
                {name: 'active', value:data[1].active },
                {name: 'trialing', value:data[1].trialing },
                {name: 'pending', value:data[1].pending },
                {name: 'past due', value:data[1].past_due },
                {name: 'unpaid', value:data[1].past_due }
            ]);

            setFreePlanData([
                {name: 'active', value:data[2].active },
                {name: 'trialing', value:data[2].trialing },
                {name: 'pending', value:data[2].pending },
                {name: 'past due', value:data[2].past_due },
                {name: 'unpaid', value:data[2].past_due }
            ]);

            setVipPlanData([
                {name: 'active', value:data[3].active },
                {name: 'trialing', value:data[3].trialing },
                {name: 'pending', value:data[3].pending },
                {name: 'past due', value:data[3].past_due },
                {name: 'unpaid', value:data[3].past_due }
            ])
      
        }
    },
    { keepPreviousData: true, staleTime: 5000 }
  );


  const {status, orderData } = useQuery(
    [API_GET_CUSTOMER_ORDERS_TIMESERIES, customerOrderData],
    () => fetchCustomerOrdersTimeseries(),
    {
        onSuccess: (data) => {            
            setCustomerOrderData(data);
        }
    },
    { keepPreviousData: true, staleTime: 5000 }
  );


  
  const formatDate = (date) => {
      return moment(date).format('MM-YYYY');
  }




  return (
    <div className='orders-template template-reports'>

        <div className="headers-container">
            <Typography variant="h4" className="text__header text--nunito text--upper">
            Reports
            </Typography>
        </div>

    
        <div className="reports-main">


        <Grid container spacing={2} className="reports-plan-pies">
            
        <Grid item xs={4}>
            <Paper>
            <Typography variant="h5" >Growth Plan</Typography>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Pie
                        data={growthPlanData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                    {growthPlanData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
            </Paper>
        </Grid>
        <Grid item xs={4}>
            <Paper>
            <Typography variant="h5">Scale Plan</Typography>
            <ResponsiveContainer width="100%" height="100%">
                <PieChart width={400} height={400}>
                    <Pie
                        data={scalePlanData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                    {scalePlanData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
            </Paper>
        </Grid>

        <Grid item xs={4}>
            <Paper elevation={1}>
            <Typography variant="h5" color="">VIP Plan</Typography>
            <ResponsiveContainer width="100%" height="100%">
                
                <PieChart width={400} height={400}>
                    <Pie
                        data={vipPlanData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        label
                        outerRadius={80}
                        fill="#8884d8"
                        dataKey="value"
                    >
                    {vipPlanData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                    
                    </Pie>
                    <Tooltip />
                </PieChart>
            </ResponsiveContainer>
            </Paper>
        </Grid>


        <Grid item xs={12}>
            <Paper>
                    
            <div className="reports-header">
                <Typography variant="h5" >Shipping Costs - Customer Orders</Typography>
                {customerOrderData && 
                <CSVLink filename={"shipping-report.csv"} onClick={() => toast.success('Exported!')} className="button__export text--nunito text--upper" data={customerOrderData} headers={headers}>
                Export
                </CSVLink>
                }
            </div>

            <ResponsiveContainer width="100%" height="100%">
                <BarChart
                    width={500}
                    height={300}
                    data={customerOrderData}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                    >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      
                        dataKey="date"
                        tickFormatter = {(date) => formatDate(date)} 
                    >
                     
                    </XAxis>
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="total_shipping_cost" stackId="a" fill="#8884d8" />
                    <Bar dataKey="total_blanka_shipping_cost" stackId="a" fill="#82ca9d" />
                </BarChart>
            </ResponsiveContainer>
            </Paper>
        </Grid>

        </Grid>

        


        </div>


        
    </div>
  )
}

export default Reports;