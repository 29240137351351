import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query'
import BrandPlateOrders from './views/BrandPlateOrders.jsx';
import Orders from './views/Orders';
import Reports from './views/Reports';
import BrandPlateOrderDetails from './views/BrandPlateOrderDetails';
import ShopifyOrderDetails from './views/ShopifyOrderDetails';
import InventoryOrderDetails from './views/InventoryOrderDetails';
import SampleOrderDetails from './views/SampleOrderDetails';
import { ToastContainer } from 'react-toastify';
import {ErrorBoundary} from 'react-error-boundary'
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from '@mui/styles';
import './App.scss';
import {theme} from './theme';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import SideBar from './components/SideBar';

function ErrorFallback({error, resetErrorBoundary}) {
  return (
    <div role="alert" className='error-container'>
      <h4>Something went wrong:</h4>
      <p>{error.message}</p>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

function App() {
  const queryClient = new QueryClient();
  return (

    <ThemeProvider theme={theme}>
      <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar
          newestOnTop={false}
          theme="colored"
          closeOnClick
          limit={2}
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          icon={false}
        />
      <Router>
        <div className="App">
          <SideBar/>
          <QueryClientProvider client={queryClient}>
            <main  className="main-content">
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                // reset the state of your app so the error doesn't happen again
              }}
            >
              <Switch>
                <Route exact path="/" to="/" component={Orders} />
                <Route exact path="/brand-plate-orders" to="/brand-plate-orders" component={BrandPlateOrders} />
                <Route exact path="/brand-plate-orders/:id" to="/brand-plate-orders" component={BrandPlateOrderDetails} />
                <Redirect from="/shopify-orders/:id" to="/customer-orders/:id"/>
                <Route exact path="/customer-orders/:id" to="/customer-orders/:id" component={ShopifyOrderDetails} />
              
                <Route exact path="/inventory-orders/:id" to="/inventory-orders/:id" component={InventoryOrderDetails} />
                <Route exact path="/sample-orders/:id" to="/sample-orders/:id" component={SampleOrderDetails} />
                <Route exact path="/reports" to="/reports" component={Reports} />

           

              </Switch>
              </ErrorBoundary>
            </main>
          </QueryClientProvider>
        </div>
      </Router>
      
    </ThemeProvider>
  );
}

export default App;