import axios from 'axios';
import {TOKEN,API_URL, PAGE_SIZE} from './constants';



export const fetchOrders = async (page = 0, filter= 'ALL', rowsPerPage = 50) => {
  const { data } = await axios.get(`${API_URL}/api/admin/orders/?page=${page}&page_size=${rowsPerPage}&order_type=${filter}`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  });
  return data;
}

export const fetchFilteredOrders = async (orderType) => {
  const { data } = await axios.get(`${API_URL}/api/admin/orders/?order_type=${orderType}&page_size=${PAGE_SIZE}`, {
    headers: {
      'Authorization': `token ${TOKEN }`
    }
  })
  // console.log(data)
  return data;
}


export const API_GET_CUSTOMER_ORDERS_TIMESERIES = 'reports_get-customer-orders-timeseries';
export const API_GET_PLAN_COUNTS = 'reports_get-plan-counts'