import React from 'react';

import { Button } from '@mui/material';

export default function RedirectButton(props) {
  const { redirectHome } = props;

  return (
    <Button
      color='primary'
      variant='contained'
      className='button__export'
      onClick={redirectHome}>
      Back to all orders
    </Button>
  );
}
