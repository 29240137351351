import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

const COLOR_PRIMARY = '#d46e5a';
const COLOR_GRAY = '#5e5e5e';
const COLOR_MEDIUM_GRAY = '#979797';
const COLOR_BLUE = '#246071';


export const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#d46e5a',
    },
    secondary: {
      main: '#246071',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#5e5e5e',
    },
    error: {
      main: '#ab0000',
    },
    info: {
      main: '#284b54',
    },
    success: {
      main: '#00AA51',
    },
  },
  typography: {
    fontFamily: 'Do Hyeon',
  },
  shape: {
    borderRadius: 16,
  },
});