import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function ConfirmDialog(props) {
    const {open, handleClose, handleConfirm, close, formData } = props

    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby='Confirm Action Dialog'
          className='confirm-dialog-template'>
          <Typography onClick={close} className='button__modal-close'>
            [x] close
          </Typography>
          <DialogTitle className='text__title text--nunito text--bold'>
            {'Confirm?'}
          </DialogTitle>
          <DialogContent className='text__content text--nunito'>
            <div id='alert-dialog-slide-description'>
              Please double check the details of this order before confirming.
              This will trigger an email to the merchant and change their
              order’s status.
              {!formData.tracking_code ? (
                <div>
                  <p className='text--nunito text__warning text--bold'>
                    Please confirm that the tracking number is inputted before submitting.
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          </DialogContent>
          <DialogActions className='actions'>
            <Button
              
              className='button--primary button__confirm'
              color='primary'
              variant='contained'
              onClick={handleConfirm}>
              Continue
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}