import React from "react";
import { fetchBrandPlateOrders } from '../api/brandPlateOrdersApi';
import { useQuery } from 'react-query';

import { useTheme } from '@mui/material/styles';

import IconButton from '@mui/material/IconButton';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { Box } from '@mui/material'

export default function TablePaginationBrandPlate(props) {
  const theme = useTheme();
  const { page, onChangePage } = props;

  const { data } = useQuery(
    ['brand-plate-orders', page],
    () => fetchBrandPlateOrders(page),
    { keepPreviousData: true, staleTime: 5000 }
  )

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
    // console.log(data.next);
  };

  const isDisabled = () => {
    if(!data?.next) {
      return true;
    }
  }

  return (
    <Box sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
        size="small"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={isDisabled()}
        aria-label="next page"
        size="small"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </Box>
  );
}
