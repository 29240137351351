import axios from 'axios';
import {TOKEN, API_URL} from './constants';

export const fetchSampleDetails = async (id) => {
  const { data } = await axios.get(`${API_URL}/api/admin/sample-orders/${id}`, {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  })
  // console.log(data)
  return data;
}

export const addInputDetailsSample =  (data) => {
  return axios.patch(`${API_URL}/api/admin/sample-orders/${data.id}/`, 
  data,
  {
    headers: {
      'Authorization': `token ${TOKEN}`
    }
  }
  )
}