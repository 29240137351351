import moment from "moment";
import React, { useState } from "react";
import { useQuery, useMutation } from 'react-query';
import { useHistory, Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';
import ConfirmDialog from '../components/confirmDialog';
import { SHIPPED } from '../api/constants';
import { useSort } from '../hooks';
import { fetchShopifyDetails, addInputDetailsShopify } from '../api/shopifyApi';
import RedirectButton from '../components/RedirectButton';

import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DoneIcon from '@mui/icons-material/Done';
import { CARRIER_OPTIONS, ORDER_STATUS_OPTIONS, headers, LBS_CONVERSION } from '../api/constants';
import OrderDataContainer from '../components/OrderDataContainer';

import {
  Typography,
  Button,
  Card,
  TextField,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

function ShopifyOrderDetails(props) {
  const { id } = props.match.params;
  let history = useHistory();
  const sort = useSort();
  const [orderNote, setOrderNote] = useState();
  const [loading, setLoading] = useState();
  const [trackingNumber, setTrackingNumber] = useState('');
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [formData, setFormData] = useState({});


  const { control, handleSubmit, setValue, formState } = useForm({
    defaultValues: {
      tracking_code: trackingNumber,
    },
    mode: 'onChange',
  });

  const { isDirty, isValid } = formState;

  const { status, data } = useQuery(
    ['shopify-order', id],
    () => fetchShopifyDetails(id),

    {
      onSuccess: (data) => {


        console.log(data)
        setOrderNote(data.note)


        if(!isDirty){
          setTrackingNumber(data.tracking_code);

          setValue('tracking_code', data.tracking_code, { shouldValidate: true });
          setValue('note', data.note, { shouldValidate: false });
          setValue('blanka_shipping_cost', data.blanka_shipping_cost, {
            shouldValidate: true,
          });
          setValue('order_status_select', {label: data.status, value: data.status },{ shouldValidate: true })
          setValue('carrier_select', {label: data.carrier, value: data.carrier }, { shouldValidate: true })
       
        }
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 500000
    }
  );

  const redirectHome = () => {
    history.goBack();
  };

  const { mutate } = useMutation(addInputDetailsShopify)

  const onSubmit = (formData) => {
    if (formData.order_status_select.value === SHIPPED) {
      setConfirmModalOpen(true);
      
      //save form data for use after confirmed
      
      setFormData(formData);
    } else {
      mutate({
        id: id,
        blanka_shipping_cost: formData.blanka_shipping_cost,
        tracking_code: formData.tracking_code,
        carrier: formData.carrier_select.value?formData.carrier_select.value:'',
        status: formData.order_status_select.value?formData.order_status_select.value:'',
        note: formData.note,
      }, {
        onSuccess: () => {
          toast.success('Order Updated')
        },
        onError: (error) => {
          toast.error(error)
        }
      })
    }
  };

  const handleNoteClick = () => {

    setLoading(true)
    mutate({
      id: id,
      note: orderNote,
    }, {
      onSuccess: () => {
        toast.success('Order Updated')
        setLoading(false)
      },
      onError: (error) => {
        toast.error(error)
        setLoading(false)
      }
    })
  };


  const confirmSubmit = () => {
    setConfirmModalOpen(false);


    mutate({
      id: id,
      blanka_shipping_cost: formData.blanka_shipping_cost,
      tracking_code: formData.tracking_code,
      carrier: formData.carrier_select.value?formData.carrier_select.value:'',
      status: formData.order_status_select.value?formData.order_status_select.value:'',
      note: formData.note,
    }, {
      onSuccess: () => {
        toast.success('Order Updated')
      },
      onError: (error) => {
        toast.error(error)
      }
    })
  };

  const handleModalClose = () => {
    setConfirmModalOpen(false);
  };

  return (
    <div className='order-details-template'>
      <div className='headers-container'>
        <Typography
          variant='h4'
          className='text__header text--nunito text--upper'>
          Customer Order Page
        </Typography>

    
        <div>
          {data?.items && (
            <CSVLink
              filename={`${data.id}-customer-order.csv`}
              onClick={() => toast.success('Exported!')}
              className='button__export MuiButtonBase-root MuiButton-root text--nunito text--upper'
              data={data.items}
              headers={headers}>
              Export
            </CSVLink>
          )}

          <RedirectButton redirectHome={redirectHome} />
        </div>
      </div>

      {status === 'loading' ? (
        <Typography className="text--nunito text--bold text--upper pt-50 text--fs-20">Loading, Please Wait</Typography>
      ) : status === 'error' ? (
        'Error'
      ) : (
        <div>
          <div className='title-container'>
            <Typography
              variant='h4'
              className='text--bold text__order-title text--nunito text--upper'>
              External Order {data.blanka_order_id}
            </Typography>

            {data?.shop_has_brand_plate && (
              <Chip
                color='primary'
                label='Has Brand Plate'
                deleteIcon={<DoneIcon />}
              />
            )}


            <Link
              className='external-link'
              to={{
                pathname: `https://admin.blankabrand.com/admin/orders/order/${data.id}`,
              }}
              target='_blank'>
              <OpenInNewIcon />
            </Link>

            {data.has_error && (
              <Chip color='primary' label='Error' deleteIcon={<DoneIcon />} />
            )}
     
          {loading &&
            <CircularProgress size={20} className="ml-15"/>
          }

          </div>

          <Card className='main-container' variant='outlined'>
            <div className='order-container text--upper text--nunito pb-20'>
  

              {data.shop_logo &&
                <div className="logo-container">
                    <p>Shop Logo</p>
                    <img src={data?.shop_logo}></img>
                </div>  
              }

              <OrderDataContainer title='Order ID' orderDetail={data.id} />


              <OrderDataContainer title='Order Number' orderDetail={data.external_order_number} />

              

              <OrderDataContainer
                title='Order Date'
                orderDetail={moment(data.created_at).format('MMMM D YYYY, h:mm:ss a')}
              />

              <OrderDataContainer
                title='Merchant ID'
                isLink
                linkUrl={`https://api.blankabrand.com/admin/shop/shop/${data?.shop_id}`}
                orderDetail={data?.shop_id}
              />

              <OrderDataContainer
                title='Merchant Name'
                orderDetail={data?.shop_name}
              />

              <OrderDataContainer
                title='Merchant Email'
                orderDetail={data?.shop_email}
              />

            <OrderDataContainer
                title='Merchant Plan'
                orderDetail={data?.shop_plan}
              />

              <OrderDataContainer
                title='M. Subs. Status'
                orderDetail={data?.shop_subscription_status}
              />


              <OrderDataContainer isLink={true} linkUrl={data.shop.google_drive_url} title='Shop Drive Link' orderDetail='Google Drive Folder' />


              <OrderDataContainer title='Status' orderDetail={data.status} />

              <OrderDataContainer
                title='Carrier'
                orderDetail={`${!data.carrier ? '' : data.carrier}`}
              />

              <div className='order-data-container'>
                <Typography className='text__information mw-300'>
                  Tracking Number:
                </Typography>
                {!data.tracking_code ? (
                  ''
                ) : (
                  <a
                    target='_blank'
                    href={`https://parcelsapp.com/en/tracking/${data.tracking_code}`}
                    className='text__information text--bold'
                    rel='noreferrer'>
                    {data.tracking_code}
                  </a>
                )}
              </div>


              <OrderDataContainer
                title='VIP Insert'
                orderDetail={data?.shop_has_insert }
              />


              <div className={`order-data-container `}>
                  <Typography className={`text__information mw-300`}>
                      Note
                  </Typography>
                  <div className="input-group--stacked">

                    <TextField className=" text__information inline-input" multiline maxRows={6}
                      value={orderNote}
                      onChange={(e)=>setOrderNote(e.target.value)}
                    />
                    <IconButton 
                      size="small"
                      color="primary"
                      onClick={()=>handleNoteClick()}
                      loading={loading}
                      loadingPosition="start"
                      variant="contained"
                    >
                    <SaveIcon></SaveIcon>
                  </IconButton >
                
                  </div>
                </div>

           
            </div>

            <div className='order-container text--upper text--nunito pt-20 pb-20'>
              <OrderDataContainer
                title='Subtotal'
                orderDetail={`$${data.order_sub_total}`}
              />

              <OrderDataContainer
                title='Shipping Cost'
                orderDetail={`$${data.order_shipping_total}`}
              />

              <OrderDataContainer
                title='Blanka Shipping Cost'
                orderDetail={`${
                  !data.blanka_shipping_cost
                    ? 'N/A'
                    : `$${data.blanka_shipping_cost}`
                }`}
              />


              <OrderDataContainer
                title='Processing Fee'
                orderDetail={`$${data.order_fees}`}
              />

              <OrderDataContainer
                title='Branded Box Fee'
                orderDetail={`$${data.order_branded_box_fees}`}
              />

              <OrderDataContainer
                totalClass
                title='Total'
                orderDetail={`$${data.order_total}`}
              />
              <OrderDataContainer
                
                title='Product Count'
                orderDetail={`${data.number_of_items}`}
              />
              <OrderDataContainer
                title='Weight'
                orderDetail={`${data.order_weight}g / ${(data.order_weight/1000).toFixed(2)}kg / ${(data.order_weight*LBS_CONVERSION ).toFixed(2)}lbs`}
              />
            </div>

            <div className='pb-10 pt-20 order-container order-container__shipping-information text--upper text--nuntio'>
              <OrderDataContainer
                title='Full Name'
                orderDetail={`${data.shipping_address?.first_name?data.shipping_address?.first_name:''} 
                ${data.shipping_address?.last_name?data.shipping_address?.last_name:''}`}
              />

              <OrderDataContainer
                title='Email'
                orderDetail={data?.shipping_address?.email}
              />

              <OrderDataContainer
                title='Phone'
                orderDetail={data?.shipping_address?.phone}
              />


              <OrderDataContainer
                title='City'
                orderDetail={data?.shipping_address?.city}
              />

              <OrderDataContainer
                title='Country'
                orderDetail={data?.shipping_address?.country}
              />

              <OrderDataContainer
                title='Address One'
                orderDetail={data.shipping_address?.address_1}
              />

              <OrderDataContainer
                title='Address Two'
                orderDetail={
                  data?.shipping_address?.address_2
                }
              />

              <OrderDataContainer
                title='Province'
                orderDetail={data.shipping_address?.state}
              />

              <OrderDataContainer
                title='Postal/Zip Code'
                orderDetail={data.shipping_address?.postcode}
              />

              <OrderDataContainer
                title='Phone Number'
                orderDetail={data.shipping_address?.phone}
              />

              {data.use_own_inventory && (
                <div className='order-data-container pt-15'>
                  <CheckIcon className='pr-20' />
                  <Typography className='text__information'>
                    Fulfill using my merchants inventory
                  </Typography>
                </div>
              )}
            </div>

            <div className='table-list-container'>
              <TableContainer component={Paper}>
                <Table padding='normal' size='small' aria-label='table'>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className='text--upper text--nunito text--bold'
                        align='left'>
                        #
                      </TableCell>
                      <TableCell
                        className='text--upper text--nunito text--bold'
                        align='left'>
                        SKU
                      </TableCell>
                      <TableCell
                        className='text--upper text--nunito text--bold'
                        align='left'>
                        Product
                      </TableCell>
                      <TableCell
                        className='text--upper text--nunito text--bold'
                        align='left'>
                        Color Name
                      </TableCell>
                      <TableCell
                        className='text--upper text--nunito text--bold'
                        align='left'>
                        Branded Box
                      </TableCell>
                      <TableCell
                        className='text--upper text--nunito text--bold'
                        align='left'>
                        Quantity
                      </TableCell>
                      <TableCell
                        className='text--upper text--nunito text--bold'
                        align='left'>
                        Cost
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.items
                    .sort((a, b) => sort(a.sku,b.sku))
                    .map((item,index) => (
                      <TableRow
                        key={item.sku}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 1 },
                        }}
                        className={`${item.quantity>1?'row--green':''}`}
                        >
                        <TableCell padding='none' align='left'>
                          {index+1}
                        </TableCell>
                        <TableCell padding='none' align='left'>
                          <Tooltip title={item.merchant_sku} placement="top-start">
                          <p>{item.sku}</p>
                        </Tooltip>
                        </TableCell>
                        <TableCell padding='none' align='left'>
                          <Tooltip title={item.title} placement="top-start">
                          <p>{item.product_title}</p>
                          </Tooltip>
                        </TableCell>
                        <TableCell padding='none' align='left'>
                          {item?.color_name}
                        </TableCell>
                        <TableCell padding='none' align='left'>
                          {item.branded_box ? <CheckIcon /> : ''}
                        </TableCell>
                        <TableCell padding='none' align='left'  className={`${item.quantity>1?'text--bold':''}`}>
                          {item.quantity}
                        </TableCell>
                        <TableCell padding='none' align='left'>
                          ${item.cost}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className='inputs-container'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className='values-container pb-10'>
                  <Typography className='mw-420  text__input-type text--upper text--nunito'>
                    Tracking Number:
                  </Typography>
                  <Controller
                    name='tracking_code'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                      />
                    )}
                    rules={{ required: false }}
                  />
                </div>

                <div className='values-container pb-10'>
                  <Typography className='mw-420 text__input-type text--upper text-nunito'>
                    Order Status:
                  </Typography>

                  <Controller
                    name='order_status_select'
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        classNamePrefix="react-select"
                        autosize={false}
                        options={ORDER_STATUS_OPTIONS}
                      />
                    )}
                  />
                </div>

                <div className='values-container pb-10'>
                  <Typography className='mw-420  text__input-type text--upper text-nunito'>
                    Carrier:
                  </Typography>

                  <Controller
                    name='carrier_select'
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        classNamePrefix="react-select"
                        autosize={false}
                        options={CARRIER_OPTIONS}
                      />
                    )}
                  />
                </div>

                <div className='values-container pb-10'>
                  <Typography className='mw-420 text__input-type text--upper text-nunito'>
                    Shipping COST (Paid by Blanka):
                  </Typography>

                  <Controller
                    name='blanka_shipping_cost'
                    control={control}
                    rules={{
                      
                      pattern: /^(0|0?[1-9]\d*)\.\d\d$/,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                      />
                    )}
                  />
                </div>


       
                <div className='values-container pb-10'>
                  <div className='mw-420' />
                  <Button
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmit(onSubmit)}
                    color='primary'
                    variant='contained'
                    className='button--primary text--nunito button__submit'>
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Card>

          <ConfirmDialog
            formData={formData}
            close={handleModalClose}
            open={confirmModalOpen}
            handleConfirm={() => confirmSubmit()}
          />
        </div>
      )}
    </div>
  );
}

export default ShopifyOrderDetails;
