import React from "react";
import { useQuery } from 'react-query';
import { fetchBrandPlateDetails } from "../api/brandPlateOrdersApi";
import { useHistory } from "react-router-dom";

import { Card, Typography } from '@mui/material';

import OrderStatusSelect from '../components/OrderStatusSelect';
import RedirectButton from "../components/RedirectButton";

function BrandPlateOrderDetails(props) {
  const { id } = props.match.params
  let history = useHistory();

  const { status, data } = useQuery(
    ['orders', id],
    () => fetchBrandPlateDetails(id)
  )

  const redirectHome = () => {
    history.push('/brand-plate-orders');
  };

  return (
    <div className='order-details-template'>
      <div className='headers-container'>
        <Typography
          variant='h4'
          className='text__header text--nunito text--upper'>
          Brand Plate Order Page
        </Typography>

        <RedirectButton redirectHome={redirectHome} />
      </div>

      {status === 'loading' ? (
        <Typography className="text--nunito text--bold text--upper pt-50 text--fs-20">Loading, Please Wait</Typography>
      ) : status === 'error' ? (
        <Typography className='text__information text--upper pt-25'>
          {' '}
          Error{' '}
        </Typography>
      ) : (
        <div>
          <div className='title-container'>
            <Typography
              variant='h4'
              className='text--bold text__order-title text--nunito text--upper'>
              Brand Plate Order {id}
            </Typography>
          </div>

          <Card className='main-container' variant='outlined'>
            <div className='order-container text--upper text--nunito pb-20'>
              <div className='order-data-container'>
                <Typography className='text__information mw-420'>
                  Order Id:
                </Typography>
                <Typography className='text__information text--bold'>
                  {id}
                </Typography>
              </div>

              <div className='order-data-container'>
                <Typography className='text__information mw-420'>
                  Created At:
                </Typography>
                <Typography className='text__information text--bold'>
                  {data.created_at.slice(0, 10)}
                </Typography>
              </div>

              <div className='order-data-container'>
                <Typography className='text__information mw-420'>
                  Shop URL:
                </Typography>
                <Typography className='text__information text--bold'>
                  {data.shop.url}
                </Typography>
              </div>

              <div className='order-data-container'>
                <Typography className='text__information mw-420'>
                  Merchant Email:
                </Typography>
                <Typography className='text__information text--bold'>
                  {data.shop_email}
                </Typography>
              </div>

              <div className='order-data-container'>
                <Typography className='text__information mw-420'>
                  Payment Id:
                </Typography>
                <Typography className='text__information text--bold'>
                  {data.charge_id}
                </Typography>
              </div>
            </div>

            <div className='inputs-container pt-25'>
              <div className='values-container'>
                <Typography className='pr-25 text__input-type text--upper text-nunito'>
                  Order Status:
                </Typography>

                <OrderStatusSelect
                  id={id}
                  orderType='BRAND_PLATE'
                  orderStatus={data.status}
                />
              </div>

              <div className='values-container'>
                <Typography className='pr-112 text__input-type text--upper text-nunito'>
                  Logo:
                </Typography>

                <img
                  className='logo pt-20'
                  src={data.shop.logo}
                  alt='Logo'></img>
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
}

export default BrandPlateOrderDetails;