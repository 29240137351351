import React from 'react';

import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import Logo from '../images/logo.png';

import { Drawer, ListItem, Divider, ListItemIcon, ListItemText, Icon } from '@mui/material'

import routes from '../routes';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  drawer: {
    width: drawerWidth,
    overflow: 'hidden',
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
  },
  drawerPaper: {
    width: drawerWidth,
    paddingTop: '0px',
    backgroundColor: theme.palette.white,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  listItem: {
    root: {
    },
  },
  categoryHeaderText: {
    fontSize: 12,
    letterSpacing: '2.4px !important',
    fontWeight: 400,
    color: theme.palette.gray,
    textTransform: 'uppercase',
  },
  categorySubText: {
    fontSize: 8,
    letterSpacing: '2.4px',
    fontWeight: 400,
    marginLeft: '10px',
    color: theme.palette.gray,
    textTransform: 'uppercase',
  },
  content: {
    flexGrow: 1,
    color: 'white',
    padding: theme.spacing(3),
  },
  icon: {
    color: theme.palette.gray,
    // minWidth: '40px',
  },
  sidebarList: {
    paddingTop: '20px',
  },
  nested: {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));

export default function SideBar(props) {
  const classes = useStyles()

  return (
    <div className={`${classes.root} side-bar`}>
      <Drawer
      className={classes.drawer}
      variant="permanent"
      anc r="left"
      classes={{ paper: classes.drawerPaper }}
      >
        <div>
          <img alt="Logo" src={Logo} style={{ width: '100px', padding: '10px', margin: '0 auto' }} />
        </div>
        <Divider />

        {routes.filter((route) => route.menu).map((route, index) => (
          <div key={index}>
            <NavLink
              activeClassName="activeItem"
              exact={route.exact}
              to={route.path}
              style={{ textDecoration: 'none' }}
              key={index}
            >
              <ListItem button key={route.name}>
                <ListItemIcon>
                  <Icon className={classes.icon}>{route.icon}</Icon>
                </ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.categoryHeaderText,
                  }}
                  primary={route.name}
                />

              </ListItem>
            </NavLink>
          </div>
        ))}
      </Drawer>
    </div>
  )
}