import moment from "moment";
import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { useHistory, Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DoneIcon from "@mui/icons-material/Done";
import { useSort } from "../hooks";
import { CircularProgress } from "@mui/material";
import { fetchSampleDetails, addInputDetailsSample } from "../api/sampleApi";
import RedirectButton from "../components/RedirectButton";
import OrderDataContainer from "../components/OrderDataContainer";
import ConfirmDialog from "../components/confirmDialog/confirmDialog";
import { SHIPPED } from "../api/constants";
import {
  CARRIER_OPTIONS,
  ORDER_STATUS_OPTIONS,
  LBS_CONVERSION,
} from "../api/constants";

import {
  Typography,
  Button,
  Card,
  TextField,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

function SampleOrderDetails(props) {
  const { id } = props.match.params;
  let history = useHistory();
  let sort = useSort();
  const [orderNote, setOrderNote] = useState();
  const [loading, setLoading] = useState();
  const [trackingNumber, setTrackingNumber] = useState("");
  const [blankaShippingCost, setBlankaShippingCost] = useState(0);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [formDataState, setFormDataState] = useState({});

  const { control, handleSubmit, setValue, formState } = useForm({
    defaultValues: {
      tracking_code: trackingNumber,
      blanka_shipping_cost: blankaShippingCost,
    },
    mode: "onChange",
  });

  const { isDirty, isValid } = formState;

  const { status, data } = useQuery(
    ["sample-order", id],
    () => fetchSampleDetails(id),
    {
      onSuccess: (data) => {
        setOrderNote(data.note);

        if (!isDirty) {
          setTrackingNumber(data.tracking_code);
          setBlankaShippingCost(data.blanka_shipping_cost);

          setValue("tracking_code", data.tracking_code, {
            shouldValidate: true,
          });
          setValue("note", data.note, { shouldValidate: false });
          setValue("blanka_shipping_cost", data.blanka_shipping_cost, {
            shouldValidate: true,
          });
          setValue(
            "order_status_select",
            { label: data.status, value: data.status },
            { shouldValidate: true }
          );
          setValue(
            "carrier_select",
            { label: data.carrier, value: data.carrier },
            { shouldValidate: true }
          );
        }
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchInterval: 500000,
    }
  );

  const { mutate } = useMutation(addInputDetailsSample);

  const onSubmit = (formData) => {
    if (formData.order_status_select.value === SHIPPED) {
      setConfirmModalOpen(true);

      setFormDataState(formData);
    } else {
      mutate(
        {
          id: id,
          blanka_shipping_cost: formData.blanka_shipping_cost,
          tracking_code: formData.tracking_code,
          carrier: formData.carrier_select.value
            ? formData.carrier_select.value
            : "",
          status: formData.order_status_select.value
            ? formData.order_status_select.value
            : "",
          note: formData.note,
        },
        {
          onSuccess: () => {
            toast.success("Order Updated");
          },
          onError: (error) => {
            toast.error(error);
          },
        }
      );
    }
  };

  const handleNoteClick = () => {
    setLoading(true);
    mutate(
      {
        id: id,
        note: orderNote,
      },
      {
        onSuccess: () => {
          toast.success("Order Updated");
          setLoading(false);
        },
        onError: (error) => {
          toast.error(error);
          setLoading(false);
        },
      }
    );
  };

  const handleModalClose = () => {
    setConfirmModalOpen(false);
  };

  const redirectHome = () => {
    history.goBack();
  };

  const confirmSubmit = () => {
    setConfirmModalOpen(false);

    mutate(
      {
        id: id,
        blanka_shipping_cost: formDataState.blanka_shipping_cost,
        tracking_code: formDataState.tracking_code,
        carrier: formDataState.carrier_select.value
          ? formDataState.carrier_select.value
          : "",
        status: formDataState.order_status_select.value
          ? formDataState.order_status_select.value
          : "",
        note: formDataState.note,
      },
      {
        onSuccess: () => {
          toast.success("Order Updated");
        },
        onError: (error) => {
          toast.error(error);
        },
      }
    );
  };

  return (
    <div className="order-details-template">
      <div className="headers-container">
        <Typography
          variant="h4"
          className="text__header text--nunito text--upper"
        >
          Sample Order Page
        </Typography>

        <RedirectButton redirectHome={redirectHome} />
      </div>

      {status === "loading" ? (
        <Typography className="text--nunito text--bold text--upper pt-50 text--fs-20">
          Loading, Please Wait
        </Typography>
      ) : status === "error" ? (
        "Error"
      ) : (
        <div>
          <div className="title-container">
            <Typography
              variant="h4"
              className="text--bold text__order-title text--nunito text--upper"
            >
              Sample Order {data.blanka_order_id}
            </Typography>

            {data.has_brand_plate && (
              <Chip
                color="primary"
                label="Has Brand Plate"
                deleteIcon={<DoneIcon />}
              />
            )}

            <Link
              className="external-link"
              to={{
                pathname: `https://admin.blankabrand.com/admin/orders/sampleorder/${data.id}`,
              }}
              target="_blank"
            >
              <OpenInNewIcon />
            </Link>

            {loading && <CircularProgress size={20} className="ml-15" />}
          </div>

          <Card className="main-container" variant="outlined">
            <div className="order-container text--upper text--nunito pb-20">
              {data.shop_logo && (
                <div className="logo-container">
                  <p>Shop Logo</p>
                  <img src={data?.shop_logo}></img>
                </div>
              )}

              <OrderDataContainer
                title="Order ID"
                orderDetail={data.blanka_order_id}
              />

              <OrderDataContainer
                title="Order Date"
                orderDetail={moment(data.created_at).format(
                  "MMMM D YYYY, h:mm:ss a"
                )}
              />

              <OrderDataContainer
                title="Merchant ID"
                isLink
                linkUrl={`https://api.blankabrand.com/admin/shop/shop/${data?.shop_id}`}
                orderDetail={data?.shop_id}
              />

              <OrderDataContainer
                title="Merchant Name"
                orderDetail={data?.shop_name}
              />

              <OrderDataContainer
                title="Merchant Email"
                orderDetail={data?.shop_email}
              />

              <OrderDataContainer
                title="Merchant Plan"
                orderDetail={data?.shop_plan}
              />

              <OrderDataContainer
                title="M. Subs. Status"
                orderDetail={data?.shop_subscription_status}
              />

              <OrderDataContainer
                isLink={true}
                linkUrl={data.shop.google_drive_url}
                title="Shop Drive Link"
                orderDetail="Google Drive Folder"
              />

              <OrderDataContainer title="Status" orderDetail={data.status} />

              <OrderDataContainer
                title="Carrier"
                orderDetail={`${!data.carrier ? "" : data.carrier}`}
              />

              <div className="order-data-container">
                <Typography className="text__information mw-300">
                  Tracking Number:
                </Typography>
                {!data.tracking_code ? (
                  ""
                ) : (
                  <a
                    target="_blank"
                    href={`https://parcelsapp.com/en/tracking/${data.tracking_code}`}
                    className="text__information text--bold"
                    rel="noreferrer"
                  >
                    {data.tracking_code}
                  </a>
                )}
              </div>

              <OrderDataContainer
                title="VIP Insert"
                orderDetail={data?.shop_has_insert}
              />

              <div className={`order-data-container `}>
                <Typography className={`text__information mw-300`}>
                  Note
                </Typography>
                <div className="input-group--stacked">
                  <TextField
                    className=" text__information inline-input"
                    multiline
                    maxRows={6}
                    value={orderNote}
                    onChange={(e) => setOrderNote(e.target.value)}
                  />
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => handleNoteClick()}
                    loading={loading}
                    loadingPosition="start"
                    variant="contained"
                  >
                    <SaveIcon></SaveIcon>
                  </IconButton>
                </div>
              </div>
            </div>

            <div className="order-container text--upper text--nunito pt-20 pb-20">
              <OrderDataContainer
                title="Subtotal"
                orderDetail={`$${data.subtotal}`}
              />

              <OrderDataContainer
                title="Shipping Cost"
                orderDetail={`$${data.shipping_cost}`}
              />

              <OrderDataContainer
                title="Blanka Shipping Cost"
                orderDetail={`${
                  !data.blanka_shipping_cost
                    ? "N/A"
                    : `$${data.blanka_shipping_cost}`
                }`}
              />

              <OrderDataContainer
                title="Processing Fee"
                orderDetail={`$${data.fees}`}
              />

              <OrderDataContainer
                totalClass
                title="Total"
                orderDetail={`$${data.total}`}
              />
              <OrderDataContainer
                title="Product Count"
                orderDetail={`${data.number_of_items}`}
              />
              <OrderDataContainer
                title="Weight"
                orderDetail={`${data.order_weight}g / ${(
                  data.order_weight / 1000
                ).toFixed(2)}kg / ${(
                  data.order_weight * LBS_CONVERSION
                ).toFixed(2)}lbs`}
              />
            </div>

            <div className="order-container pt-20 pb-20 text--upper text--nuntio">
              <OrderDataContainer
                title="Full Name"
                orderDetail={`${data.shipping_address.first_name} ${data.shipping_address.last_name}`}
              />

              <OrderDataContainer
                title="City"
                orderDetail={data.shipping_address.city}
              />

              <OrderDataContainer
                title="Country"
                orderDetail={data.shipping_address.country?.name}
              />

              <OrderDataContainer
                title="Address One"
                orderDetail={data.shipping_address.address_one}
              />

              <OrderDataContainer
                title="Address Two"
                orderDetail={data.shipping_address.address_two}
              />

              <OrderDataContainer
                title="Province"
                orderDetail={data.shipping_address.state}
              />

              <OrderDataContainer
                title="Postal/Zip Code"
                orderDetail={data.shipping_address.zip_code}
              />

              <OrderDataContainer
                title="Phone Number"
                orderDetail={data.shipping_address.phone}
              />
            </div>

            <div className="table-list-container">
              <TableContainer component={Paper}>
                <Table padding="normal" size="small" aria-label="table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="text--bold text--upper text--nunito"
                        align="left"
                      >
                        #
                      </TableCell>
                      <TableCell
                        className="text--bold text--upper text--nunito"
                        align="left"
                      >
                        SKU
                      </TableCell>
                      <TableCell
                        className="text--bold text--upper text--nunito"
                        align="left"
                      >
                        Product
                      </TableCell>
                      <TableCell
                        className="text--bold text--upper text--nunito"
                        align="left"
                      >
                        Color Name
                      </TableCell>
                      <TableCell
                        className="text--bold text--upper text--nunito"
                        align="left"
                      >
                        Cost
                      </TableCell>
                      <TableCell
                        className="text--bold text--upper text--nunito"
                        align="center"
                      >
                        Quantity
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {data.items
                      .sort((a, b) => sort(a.product.sku, b.product.sku))
                      .map((item, index) => (
                        <TableRow
                          key={item.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 1 },
                          }}
                          className={`${item.quantity > 1 ? "row--green" : ""}`}
                        >
                          <TableCell padding="none" align="left">
                            {index + 1}
                          </TableCell>
                          <TableCell padding="none" align="left">
                            {item.product.sku}
                          </TableCell>
                          <TableCell padding="none" align="left">
                            {item.product.name}
                          </TableCell>
                          <TableCell padding="none" align="left">
                            {item.product.color_name}
                          </TableCell>
                          <TableCell padding="none" align="left">
                            ${item.product.cost}
                          </TableCell>
                          <TableCell
                            padding="none"
                            align="center"
                            className={`${
                              item.quantity > 1 ? "text--bold" : ""
                            }`}
                          >
                            {item.quantity}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="inputs-container">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="values-container pb-10">
                  <Typography className="mw-420  text__input-type text--upper text--nunito">
                    Tracking Number:
                  </Typography>
                  <Controller
                    name="tracking_code"
                    control={control}
                    render={({ field }) => <TextField {...field} />}
                    rules={{ required: false }}
                  />
                </div>

                <div className="values-container pb-10">
                  <Typography className="mw-420 text__input-type text--upper text-nunito">
                    Order Status:
                  </Typography>

                  <Controller
                    name="order_status_select"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        classNamePrefix="react-select"
                        autosize={false}
                        options={ORDER_STATUS_OPTIONS}
                      />
                    )}
                  />
                </div>

                <div className="values-container pb-10">
                  <Typography className="mw-420  text__input-type text--upper text-nunito">
                    Carrier:
                  </Typography>

                  <Controller
                    name="carrier_select"
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={CARRIER_OPTIONS}
                        classNamePrefix="react-select"
                        autosize={false}
                      />
                    )}
                  />
                </div>

                <div className="values-container pb-10">
                  <Typography className="mw-420  text__input-type text--upper text--nunito">
                    Shipping COST (Paid by Blanka):
                  </Typography>
                  <Controller
                    name="blanka_shipping_cost"
                    control={control}
                    render={({ field }) => <TextField {...field} />}
                    rules={{
                      required: false,
                      pattern: /^(0|0?[1-9]\d*)\.\d\d$/,
                    }}
                  />
                </div>

                <div className="values-container pb-10">
                  <div className="mw-420" />
                  <Button
                    sampleOrder
                    disabled={!isValid || !isDirty}
                    onClick={handleSubmit(onSubmit)}
                    color="primary"
                    variant="contained"
                    className="button--primary text--nunito button__submit"
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Card>

          <ConfirmDialog
            formData={formDataState}
            close={handleModalClose}
            open={confirmModalOpen}
            handleConfirm={() => confirmSubmit()}
          />
        </div>
      )}
    </div>
  );
}

export default SampleOrderDetails;
