import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from 'react-query';
import { useHistory, useLocation, Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import CheckIcon from '@mui/icons-material/Check';
import { toast } from 'react-toastify';

import {UNFULFILLED} from '../api/constants'
import FilterMenu from '../components/FilterMenu';
import TablePaginationActions from '../components/TablePaginationActions';

import { fetchOrders } from '../api/api';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableFooter,
} from '@mui/material';

const ORDER_TYPES = {
  customer: "CUSTOMER",
  inventory: "INVENTORY",
  sample: "SAMPLE"
}

function useUrlQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

function Orders() {
  let history = useHistory();
  let query = useUrlQuery();
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState('ALL');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    // //get query param from url
    let queryPage = query.get('page');
    let queryFilter = query.get('filter');

      //If there is no page (null), then append the page parameter with (1)
      setPage(queryPage ? queryPage : 0);
      setFilter(queryFilter ? queryFilter : 'ALL' );
  }, []);


  const { status, data, error, } = useQuery(
    ['orders', page, filter, rowsPerPage],
    () => fetchOrders(Number(page)+1, filter, rowsPerPage),
    { keepPreviousData: true, staleTime: 5000 }
  )

  const headers = [
    { label: 'Date', key: 'created_at' },
    { label: 'Order  ID', key: 'id' },
    { label: 'Shop', key: 'shop' },
    { label: 'Shop Email', key: 'shop_email' },
    { label: 'Shop Plan', key: 'shop_plan' },
    { label: 'Order Type', key: 'order_type' },
    { label: 'Payment ID', key: 'charge_id' },
    { label: 'Order Status', key: 'status' },
    { label: 'Carrier', key: 'carrier' },
    { label: 'Tracking Code', key: 'tracking_code' },
    { label: 'Shipping Cost', key: 'shipping_cost' },
    { label: 'Total', key: 'order_total' },
    { label: 'Blanka Shipping Cost', key: 'blanka_shipping_cost' },
    { label: 'Shipping Country', key: 'shipping_country' },
  ]

  const handleChangeRowsPerPage = (event) =>{
    console.log(parseInt(event.target.value));

    setRowsPerPage(event.target.value);
    setPage(0);
  }

  const handleOrderClick = (order) => {
    if(order.order_type === ORDER_TYPES.customer){
      return `/customer-orders/${order.id}/`
    }else if(order.order_type === ORDER_TYPES.inventory){
      return `/inventory-orders/${order.id}`
    }else if (order.order_type === ORDER_TYPES.sample){
      return `/sample-orders/${order.id}`
    } 
    //toast.error('Order type not recognized');
  }



  const fetchFilter = (orderType) => {
    //set order type state to trigger api call
    setFilter(orderType);

    const params = new URLSearchParams()
    params.append("filter", orderType)
    history.push({search: params.toString()});
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    
    //set query param, this cheange the url with the page number
    const params = new URLSearchParams()
    params.append("page", newPage)
    history.push({search: params.toString()})

  };


  const getNumberOfUnfullfilledOrders = () =>{



    var unfullfilledOrders = data?.results.filter((order)=>{
      return order.status===UNFULFILLED
    })

    console.log(unfullfilledOrders)
    try{
      return unfullfilledOrders.length() || 0
    }catch{
      return 0
    }
  }

 

  return (
    <div className='orders-template'>
      <div className='headers-container'>
        <Typography
          variant='h4'
          className='text__header text--nunito text--upper'>
          All Orders
        </Typography>

        {data?.results && (
          <div>
            <CSVLink
              filename={'all-orders-report.csv'}
              onClick={() => toast.success('Exported!')}
              className='button__export MuiButtonBase-root MuiButton-root text--nunito text--upper'
              data={data.results}
              headers={headers}>
              Export
            </CSVLink>
          </div>
        )}
      </div>

  

      {status === 'loading' ? (
        <Typography className="text--nunito text--bold text--upper pt-50 text--fs-20">Loading, Please Wait</Typography>
      ) : status === 'error' ? (
        <Typography className="text--nunito text--bold text--upper pt-50 text--fs-20">{ error }</Typography>
      ) : (
        <div>
          <TableContainer className='table-container' component={Paper}>
            <div className='filter-container'>
              <FilterMenu fetchFilter={fetchFilter} filter={filter} />
            </div>
            <Table size='small' aria-label='table'>
              <TableHead>
                <TableRow>
                  <TableCell
                    className='text--upper text--nunito id-column'
                    align='left'
                    >
                    Order ID
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='left'>
                    Created At
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='left'>
                    Order Type
                  </TableCell>
               
                  <TableCell
                    className='text--upper text--nunito'
                    align='left'>
                    Shop Email
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='center'>
                    VIP Insert
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='left'>
                    Status
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='center'>
                    Carrier
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='left'>
                    Tracking Number
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='left'>
                    Payment ID
                  </TableCell>
                  <TableCell
                    className='text--upper text--nunito'
                    align='left'>
                    Note
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.results.map((result, index) => (
              
                  
                  <TableRow
                    component={Link}
                    to={handleOrderClick(result)}
                    hover
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 1 } }}
                    style={{
                      'backgroundColor': result.has_error ? '#f0111167' : '',
                    }}>
                    <TableCell align='left'>
                      {result.blanka_order_id}
                    </TableCell>
                    <TableCell padding='none' align='left'>
                      {moment(result.created_at).format('L h:mm:ss a')}
                    </TableCell>
                    <TableCell padding='none' align='left'>
                      {result.order_type}
                    </TableCell>
                   
                    <TableCell padding='none' align='left'>
                      {result.shop_email}
                    </TableCell>
                    <TableCell padding='none' align='center'>
         
                      {result.shop_has_insert == 'True' && 
                      <CheckIcon color="success"></CheckIcon> 
                      }
                    </TableCell>
                    <TableCell padding='none' align='left'>
                      {result.status}
                    </TableCell>
                    <TableCell padding='none' align='left'>
                      {result.carrier}
                    </TableCell>
                    <TableCell padding='none' align='left'>
                      {result.tracking_code}
                    </TableCell>
                    <TableCell padding='none' align='left'>
                      {result.charge_id}
                    </TableCell>
                    <TableCell padding='none' align='left'>
                      <sub>{result.note}</sub>
                    </TableCell>
                  </TableRow>
                  
                ))}
           
          
              </TableBody>
            </Table>

            <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      50,
                      100,
                      { value: 10000, label: 'All' },
                    ]}
                    rowsPerPage={rowsPerPage}
                    colSpan={3}
                    page={page}
                    count={data.count}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export default Orders;